import "./index.scss";
import "../../assets/scripts/WhirSwiper";
import "../../assets/scripts/jquery.countup.min";
import "../../assets/scripts/jquery.waypoints.min";

//棣栭〉banner
var bannerSwiper = new WhirSwiper(".index-banner .swiper-container", {
  loop: true,
  speed: 1000,
  autoplay: {
    delay: 5000,
    stopOnHover: false,//榧犳爣鎮诞鏄惁鍋滄
  },
  pagination: {
    el: '.index-banner .swiper-pagination'
  },
});


//鏂伴椈鏁堟灉
// function initnewSwiper(){
//   var newSwiper = new WhirSwiper(".news-cont .new-swiper .whir-container", {
//     pagination: {
//       el: '.news-cont .new-swiper .whir-pagination'
//     },
//     slidesPerView:3,
//     spaceBetween:60,
//     breakPoints:{
//         1024:{
//             slidesPerView:2,
//             spaceBetween:30,
//         },
//         768:{
//             slidesPerView:2,
//             spaceBetween:10,
//         },
//         500:{
//             slidesPerView:1,
//             spaceBetween:10,
//         }
//     }
//   });
// }

// function initvideoSwiper(){
//   var videoSwiper = new WhirSwiper(".news-cont .video-swiper .whir-container", {
//     pagination: {
//       el: '.news-cont .video-swiper .whir-pagination'
//     },
//     slidesPerView:3,
//     spaceBetween:60,
//     breakPoints:{
//         1024:{
//             slidesPerView:2,
//             spaceBetween:30,
//         },
//         768:{
//             slidesPerView:2,
//             spaceBetween:10,
//         },
//         500:{
//             slidesPerView:1,
//             spaceBetween:10,
//         }
//     }
//   });
// }

!(function(){
  $('.index-news .news-tab .common-more a').eq(0).addClass('show')
  $('.index-news .tab span').eq(0).addClass('active')
  $('.index-news .news-cont .swiper').eq(0).show()
  // initnewSwiper()
  // getpageWidth($('.news-cont .new-swiper .whir-pagination'))
})()

var videoinit = true
$('.index-news .tab span').on('click',function(){
  var index = $(this).index()
  $(this).addClass('active').siblings().removeClass('active')
  $('.index-news .news-tab .common-more a').eq(index).addClass('show').siblings().removeClass('show')
  if(index == 0){
    $('.index-news .news-cont .swiper').eq(0).show().siblings().hide()
  }else if(index == 1){
    $('.index-news .news-cont .swiper').eq(1).show().siblings().hide()
    // if(videoinit){
    //   initvideoSwiper()
    //   getpageWidth($('.news-cont .video-swiper .whir-pagination'))
    //   videoinit = false
    // }
  }
})

// function getpageWidth($el){
//   var totalWidth = $el.width()
//   var num = $el.find('.whir-pagination-bullet').length
//   $el.find('.whir-pagination-bullet').width(totalWidth/num+'px')
// }
// $(window).resize(function(){
//   getpageWidth($('.news-cont .new-swiper .whir-pagination'))
//   getpageWidth($('.news-cont .video-swiper .whir-pagination'))
// })

$('.counter').countUp({
  delay: 10,
  time: 2000
});